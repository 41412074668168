// app/javascript/packs/application.js
import "bootstrap"
import "controllers"

//= require jquery
//= require jquery_ujs
//= require turbolinks
//= require bootstrap
//= require_tree .
//= require hogan
//= require activestorage

import 'cocoon-js'
import "controllers"
import lottie from 'lottie-web';

// console.log("application js from packs")

document.addEventListener("DOMContentLoaded", function () {
  // Initialize Lottie animation
  const spinnerContainer = document.getElementById("lottie-spinner");

  const lottieAnimation = lottie.loadAnimation({
    container: spinnerContainer,
    renderer: "svg",
    loop: true,
    autoplay: false,
    path: "/animations/Earth.json" // Adjust the path to your JSON file
  });

  // Show Spinner
  document.addEventListener("show-spinner", function () {
    document.getElementById("spinner").classList.remove("spinner-none");
    lottieAnimation.play(); // Start the animation
  });

  // Hide Spinner
  document.addEventListener("hide-spinner", function () {
    document.getElementById("spinner").classList.add("spinner-none");
    lottieAnimation.stop(); // Stop the animation
  });
});
