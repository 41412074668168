import {post} from "@rails/request.js";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["fileName", "title", "canvas"];

    async uploadPart(e) {
        var file = e.srcElement.files[0];
        var formData = new FormData();
        formData.append("file", file);
        if (this.hasFileNameTarget) {
            this.fileNameTarget.innerHTML = file.name;
        }
        if (this.hasTitleTarget) {
            this.titleTarget.value = file.name;
        }

        await this.getScreenshots(file);
        formData.append("screenshots", this.images.join(" "));

        await post(this.element.dataset.partModelUrl, {body: formData});
    }

    async uploadNewPart(e) {
        document.dispatchEvent(new CustomEvent("show-spinner"));
        var file = e.srcElement.files[0];
        var formData = new FormData();
        formData.append("file", file);
        await this.getScreenshots(file);
        formData.append("screenshots", this.images.join(" "));
        let response = await post(this.element.dataset.partModelUrl, {
            body: formData,
        });
        if (response.ok) {
            let data = response.response;
            if (data && data.url) {
                // window.location = data.url;
                location.reload();
            } else {
                console.error("Redirect URL not found in response", data);
            }
        } else {
            console.error("Error uploading the file", response);
        }
    }


    async getScreenshots(file) {
        return new Promise((resolve) => {
            this.images = [];
            const canvas = document.createElement("canvas");
            canvas.style.display = "none";
            document.body.appendChild(canvas);
            this.stl_viewer = new StlViewer(canvas, {
                cameray: -50,
                models: [{id: 1, local_file: file}],
                model_loaded_callback: () => {
                    this.getPictures(canvas, resolve);
                },
            });
        });
    }

    getImage(canvas, x, y, z) {
        this.stl_viewer.rotate(1, x, y, z);
        var dataURL = canvas.toDataURL("image/png");
        this.images.push(dataURL);
    }

    getPictures(canvas, resolve) {
        let angles = [
            [0, 0, 2],
            [0, 0, 2],
            [0, 0, 2],
            [0, 0, 1],
            [-0.5, 0, 0],
            [-0.5, 0, 0],
            [-0.5, 0, 0],
            [-0.5, 0, 0],
            [-1, 0, 0],
        ];
        var delay = 100;
        angles.forEach((angle) => {
            delay += 100;
            setTimeout(() => this.getImage(canvas, angle[0], angle[1], angle[2]), delay);
        });
        setTimeout(() => {
            // Clean up: remove the canvas element
            if (canvas.parentNode) {
                canvas.parentNode.removeChild(canvas);
            }
            resolve();
        }, delay + 500);
    }
}
